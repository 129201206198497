import React from 'react';
import { motion } from 'framer-motion';

// Logos
import umbraco from '../../images/svgs/logos/logo-umbraco.svg';
import node from '../../images/svgs/logos/logo-node.svg';
import cSharp from '../../images/svgs/logos/logo-csharp.svg';
import ionic from '../../images/svgs/logos/logo-ionic.svg';
import angular from '../../images/svgs/logos/logo-angular.svg';
import sass from '../../images/svgs/logos/logo-sass.svg';
import aspNet from '../../images/svgs/logos/logo-aspnet.png';

import styles from './hero-logos.module.scss';

const HeroLogos = () => {
  const variants = {
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: i * 0.25,
        duration: 0.5,
        ease: 'easeOut',
      },
    }),
    hidden: {
      opacity: 0,
      scale: 0.7,
    },
  };

  return (
    <>
      <motion.img
        className={styles.umbraco}
        custom={1}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={umbraco}
        alt="logo de Umbraco"
      />
      <motion.img
        className={styles.nodeJs}
        custom={4.5}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={node}
        alt="logo de Nodejs"
      />
      <motion.img
        className={styles.cSharp}
        custom={2.5}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={cSharp}
        alt="logo de C#"
      />
      <motion.img
        className={styles.aspNet}
        custom={1.5}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={aspNet}
        alt="logo de ASP.net"
      />
      <motion.img
        className={styles.ionic}
        custom={5}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={ionic}
        alt="logo de Ionic"
      />
      <motion.img
        className={styles.angular}
        custom={3.5}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={angular}
        alt="logo de Angular"
      />
      <motion.img
        className={styles.sass}
        custom={1}
        initial="hidden"
        animate="visible"
        variants={variants}
        src={sass}
        alt="logo de Sass"
      />
    </>
  );
};

export default HeroLogos;
