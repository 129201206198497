import React from 'react';
import { motion } from 'framer-motion';
import {
  containerShowOnLanding,
  itemShowOnLanding,
} from '../../utils/animations';

import CTAButton from '../atoms/cta-button';
import HeroLogos from '../molecules/hero-logos';
import styles from './hero-banner-homepage.module.scss';

const HeroBanner = () => (
  <section className={styles.container}>
    <motion.div
      className={styles.content}
      animate="visible"
      initial="hidden"
      variants={containerShowOnLanding}
    >
      <motion.h1 className={styles.pretitle} variants={itemShowOnLanding}>
        Diseño y desarrollo web en Tenerife
      </motion.h1>

      <motion.h2 className={styles.title} variants={itemShowOnLanding}>
        Más de 20 años creando productos digitales a medida.
      </motion.h2>

      <motion.p className={styles.description} variants={itemShowOnLanding}>
        Te ayudamos a maximizar el potencial de tu empresa con las últimas
        tecnologías.
      </motion.p>
      <motion.div className={styles.button} variants={itemShowOnLanding}>
        <CTAButton to="/contacto" text="¿Hablamos?" />
      </motion.div>
    </motion.div>

    <div className={styles.logos}>
      <HeroLogos />
    </div>
  </section>
);

export default HeroBanner;
