import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { slideInFromBottom } from '../../utils/animations';

import Prelude from '../molecules/prelude';
import Testimonial from '../molecules/testimonial';
import CTAButton from '../atoms/cta-button';
import styles from './feat-case-study.module.scss';

const FeatCaseStudy = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-40px',
  });

  const images = useStaticQuery(graphql`
    query {
      caseStudyImage: file(relativePath: { eq: "intranet-cfutbol.png" }) {
        childImageSharp {
          fluid(maxWidth: 555, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <motion.section
      className={styles.caseStudy}
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      initial="hidden"
      variants={slideInFromBottom}
    >
      <Prelude
        content={{
          pretitle: 'Último proyecto destacado',
          title: 'CFutbol. Federaciones y campeonatos deportivos',
        }}
      />
      <div className={styles.container}>
        <div className={styles.image}>
          <Img
            fluid={images.caseStudyImage.childImageSharp.fluid}
            alt="Portatil con la aplicación CFutbol"
          />
        </div>
        <div className={styles.intro}>
          <h4>Gestión integral para Federaciones deportivas</h4>
          <p>
            Familia de aplicaciones para el día a día de una Federación o
            campeonato deportivo.
          </p>
        </div>
        <div className={styles.testimonial}>
          <Testimonial
            content={{
              quote:
                'JRweb nos ha ayudado a optimizar todos nuestros procesos. Su valor agregado es la relación directa y personal con un excelente trato. Trabajar con esta empresa es tener en tu equipo a un socio de confianza, responsable con los plazos, resolutivo y flexible con los cambios.',
              author: {
                pic: false,
                name: 'Giover Alegre',
                role:
                  'Departamento Licencias, Federación Interinsular de Fútbol de Tenerife',
              },
            }}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <CTAButton
          to="/trabajos/gestion-deportiva-para-federaciones-de-futbol/"
          text="Lee el informe completo"
        />
      </div>
    </motion.section>
  );
};

export default FeatCaseStudy;
