import React from 'react';

import SEO from '../components/molecules/seo';
import HeroBanner from '../components/organisms/hero-banner-homepage';
import FeatCaseStudy from '../components/organisms/feat-case-study';
import ServicesGrid from '../components/organisms/services-grid';
import Review from '../components/molecules/review';

const IndexPage = () => (
  <>
    <SEO
      title="Diseño y desarrollo web en Tenerife | JR Web"
      description="Expertos en diseño y desarrollo web en Tenerife. Más de 20 años creando productos digitales a medida. Especialistas en marketing online."
    />
    <HeroBanner />
    <FeatCaseStudy />
    <ServicesGrid />
    <Review
      content={{
        quote:
          'El Poeta libanés Gibrán Jalil Gibran dijo en una ocasión “No progresas mejorando lo que ya está hecho, sino esforzándote por lograr lo que aún queda por hacer. Esa es la esencia de la filosofía de la Federación Interinsular de Fútbol de Las Palmas. Miramos hacia el futuro y proyectamos una idea de los desarrollos informáticos que creemos, mejorarán la calidad del servicio que ofrecemos a los distintos estamentos federativos y a nuestro personal laboral, y ahí siempre ha estado JRweb con su profesionalidad y abnegada capacidad de trabajo, para convertir en realidad nuestro ideal de avance y progreso.',
        stars: 5,
        author: {
          pic: false,
          name: 'Daniel Alejandro Pita Domínguez',
          role: 'Vicesecretario General de la FIFLP',
        },
      }}
    />
  </>
);

export default IndexPage;
