import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { slideInFromBottom } from '../../utils/animations';

import Prelude from '../molecules/prelude';
import CTAButton from '../atoms/cta-button';
import styles from './services-grid.module.scss';

const ServicesGrid = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px',
  });

  return (
    <motion.section
      className={styles.services}
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      initial="hidden"
      variants={slideInFromBottom}
    >
      <Prelude
        content={{
          title: 'Nuestros servicios',
          description:
            'Te asesoraremos sin compromiso sobre los servicios que podemos ofrecerte. Desde mejorar tu presencia en Internet hasta crear desarrollos personalizados en entorno web.',
        }}
      />

      <div className={styles.grid}>
        <div className={styles.grid__item}>
          <div className={styles.number}>1.</div>
          <h3 className={styles.title}>Creación de páginas web</h3>
          <p className={styles.description}>
            Nos encargamos de todos los procesos necesarios para publicar tu web
            en Internet.
          </p>
        </div>
        <div className={styles.grid__item}>
          <div className={styles.number}>2.</div>
          <h3 className={styles.title}>Tiendas E-commerce</h3>
          <p className={styles.description}>
            Somos especialistas en desarrollo de tiendas de productos para el
            comercio en línea.
          </p>
        </div>
        <div className={styles.grid__item}>
          <div className={styles.number}>3.</div>
          <h3 className={styles.title}>
            Desarrollo de aplicaciones móviles y en entorno web
          </h3>
          <p className={styles.description}>
            Estamos especializados en realizar proyectos web a medida de las
            necesidades de nuestros clientes.
          </p>
        </div>
        <div className={styles.grid__item}>
          <div className={styles.number}>4.</div>
          <h3 className={styles.title}>
            Gestor deportivo para Federaciones o Campeonatos de fútbol
          </h3>
          <p className={styles.description}>
            Conoce nuestra herramienta de gestión deportiva utilizada por la
            Federación Canaria de Fútbol.
          </p>
        </div>
        <div className={styles.grid__item}>
          <div className={styles.number}>5.</div>
          <h3 className={styles.title}>Posicionamiento SEO y analítica web</h3>
          <p className={styles.description}>
            Et non fugiat cillum cillum Lorem consectetur labore aliqua officia
            quis
          </p>
        </div>
        <div className={styles.grid__item}>
          <div className={styles.number}>6.</div>
          <h3 className={styles.title}>Gestión de dominios y hospedajes</h3>
          <p className={styles.description}>
            Servicio de registro de dominios y de alojamiento web a precios muy
            competitivos.
          </p>
        </div>
      </div>

      <div className={styles.cta}>
        <CTAButton to="/servicios/" text="Nuestros servicios" />
      </div>
    </motion.section>
  );
};

export default ServicesGrid;
